import React ,{useState} from "react";
import Logo from './logo';
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import {SendIcon} from "../components/custom-icons/SendIcon";
import {APP_URL,API_ENDPOINT, toast_anim , pdfdoconly} from '../components/config/Common'

import {Links} from '../routes/PathLink'
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';  


import {
    Control,
    FieldErrors,
    useForm,
    useFormState,
    useWatch,
    Controller
 } from 'react-hook-form';



import { AiFillTwitterCircle, AiFillInstagram, AiFillFacebook, AiFillLinkedin } from 'react-icons/ai';

const newletterSchema = Yup.object().shape({
    email: Yup.string()
        .required('You must need to provide email address.')
        .email('Invalid email.'),       
});

let defaultValues = {  
    email:'',
};



const Footer = () => {
    const [loading, setLoading] = useState(false);
    

    const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(newletterSchema),
    });


    const onSubmitNews = (values) => {
        console.log('value', values)
        setLoading(true)        

        let input = values;
      input = {...input,
      }

      setLoading(true)
      axios.post(`${API_ENDPOINT}/add_newletter_subscription`,input,{
         headers: {
            'Content-Type': 'multipart/form-data'
         }
      }).then( (result)=> {
         console.log('data=====',result.data)
         setLoading(false)
         console.log('data=====',result)
            if(result.data.status === 1){
                //setSubmitting(false)
                //localStorage.setItem('user_name',result.data.name);
                toast_anim(result.data.message)
            }
      })

        
    }

    const buttonHandler = () => {
        alert('Hi there!');
    }   

    return (
        <div className="flex w-full flex-col border-gray-800 bg-accent-200 lg:border-b-8  dark:bg-gray-700">
            

            {/*<div className="bg-gray-100 py-10 px-5 md:px-5">
                <h3 className="text-center text-2xl text-body">Home Page Comming Soon!</h3> 
            </div> */}
            
            <div className="footersec grid w-full grid-cols-[repeat(auto-fill,minmax(260px,1fr))] gap-6 pt-16 md:grid-cols-3 xl:grid-cols-[repeat(auto-fit,minmax(220px,1fr))] xl:gap-8 2xl:gap-2 2xl:grid-cols-4 px-5 md:px-10 lg:px-[50px] xl:px-16 justify-center m-auto">
                <div className="flex flex-col">
                    <div className="mb-[2px] flex h-16 items-start">
                        <Logo />
                    </div>

                    {/*<address className="mb-7 text-sm not-italic text-heading dark:text-zinc-300 capitalize mt-5">
                    G-2, Ground Floor, Gruhalaxmi Apartments No 3 Raod, opposite D-MART, Beltarodi, Nagpur, <br/>Maharashtra 440034
                    </address>
                    <span className="mb-1 text-sm text-heading dark:text-zinc-300">
                    <a href="mailto:jafar.safecure2020@gmail.com">jafar.safecure2020@gmail.com</a>
                    </span>
                    <span className="text-sm text-heading dark:text-zinc-300">
                    <a href={`tel:+917387869666`}>+91 73878 69666</a><br/><a href={`tel:+918329720772`}>+91 83297 20772</a>
                    </span> */}
                </div>

                <div className="flex flex-col">
                <h3 className="mt-3 mb-7 text-xl  dark:text-zinc-300">
                    SERVICES
                    </h3>
                    <ul className="space-y-3">
                        <li>
                            <Link to={Links.CONTACT} className="text-sm text-heading transition-colors hover:text-secondary-color dark:text-zinc-300 dark:hover:text-orange-500">Contact</Link>
                        </li>
                        <li>
                            <Link to={Links.BECOMEANEXPERT}  className="text-sm text-heading transition-colors hover:text-secondary-color dark:text-zinc-300 dark:hover:text-orange-500">Become An Expert</Link>
                        </li>
                        <li>
                            <Link to={Links.FAQ}  className="text-sm text-heading transition-colors hover:text-secondary-color dark:text-zinc-300 dark:hover:text-orange-500">FAQ</Link>
                        </li>
                        <li>
                            <Link to={Links.BLOG} target="_blank" className="text-sm text-heading transition-colors hover:text-orange-500 dark:text-zinc-300 dark:hover:text-orange-500">Blog</Link>
                        </li>
                         <li>
                            <Link to={Links.TRACk_MENTAL_HEALTH} target="_blank" className="text-sm text-heading transition-colors hover:text-orange-500 dark:text-zinc-300 dark:hover:text-orange-500">Track Mental Health</Link>
                        </li>
                        
                    </ul>
                </div>
                <div className="flex flex-col">
                <h3 className="mt-3 mb-7 text-xl  dark:text-zinc-300">
                    BROWSE
                    </h3>
                    <ul className="space-y-3">
                        <li>
                            <Link to={Links.ABOUT} className="text-sm text-heading transition-colors hover:text-secondary-color dark:text-zinc-300 dark:hover:text-orange-500">About</Link>
                        </li>
                        <li>
                            <Link to={Links.TERMS} className="text-sm text-heading transition-colors hover:text-secondary-color dark:text-zinc-300 dark:hover:text-orange-500">Terms and conditions</Link>
                        </li>
                        <li>
                            <Link to={Links.PRIVACY} className="text-sm text-heading transition-colors hover:text-secondary-color dark:text-zinc-300 dark:hover:text-orange-500">Privacy Policy</Link>
                        </li>
                        <li>
                            <Link to={Links.TESTIMONIALS} className="text-sm text-heading transition-colors hover:text-orange-500 dark:text-zinc-300 dark:hover:text-orange-500">Testimonials</Link>
                        </li> 
                         
                    </ul>
                </div>

                <div className="flex flex-col">
                    {/*<h3 className="mt-3 mb-7 text-xl font-semibold text-heading dark:text-zinc-300">
                    Subscribe Now
                    </h3>
                    <p className="mb-7 text-sm text-heading dark:text-zinc-300">Subscribe your email for newsletter and featured news based on your interest</p>
                    <form onSubmit={handleSubmit(onSubmitNews)}>
                    <div className="flex flex-col">
                        <div className="relative w-full rounded border border-gray-200 bg-gray-50 ltr:pr-11 rtl:pl-11">
                            <input
                                type="email"
                                {...register('email')}
                                id="email_subscribe"                                
                                placeholder={'Write your email here'}
                                className="h-14 w-full border-0 bg-transparent text-sm text-body outline-none focus:outline-none ltr:pl-5 rtl:pr-5"
                               
                            />

                            <button className="absolute top-1/2 -mt-2 ltr:right-3 rtl:left-3"><SendIcon className="text-gray-500 transition-colors hover:text-accent" /></button>
                        </div>
                        <div className="text-red-500 text-sm">{errors.email?.message}</div>
                    </div>
                    </form>*/}
                    <h3 className="mt-5 mb-3 text-xl  dark:text-zinc-300">
                    Follow us on
                    </h3>
                    <div className="flex">
                        <a href="https://www.facebook.com/healthlogist" rel="noreferrer" target="_blank" className="mr-2 text-3xl text-accent hover:text-[#1877f2]"><AiFillFacebook /></a>
                        <a href="https://x.com/healthlogist1" rel="noreferrer" target="_blank" className="mr-2 text-3xl text-accent hover:text-[#1da1f2]"><AiFillTwitterCircle /></a>
                        <a href="https://www.instagram.com/healthlogist1" rel="noreferrer" target="_blank" className="mr-2 text-3xl text-accent hover:text-[#c13584]"><AiFillInstagram /></a>
                        <a href="https://www.linkedin.com" rel="noreferrer" target="_blank" className="mr-2 text-3xl text-accent hover:text-[#0A66C2]"><AiFillLinkedin /></a>
                        
                    </div>
                </div>
            </div>
            <div className="mt-8 flex w-full flex-col items-center border-t border-gray-200 pt-8 pb-12 lg:mt-0 lg:flex-row lg:justify-between lg:border-t-0 px-5 md:px-10 lg:px-[50px] xl:px-16">
                <span className="order-2 text-sm text-heading lg:order-1 dark:text-zinc-300">
                &copy; Copyright {new Date().getFullYear()}{' '}
                <Link
                    className="font-bold text-heading transition-colors hover:text-accent dark:text-zinc-100 dark:hover:text-accent"
                    to='/'
                >
                   HealthLogist
                </Link>{' '}
                All rights reserved
                </span>
            </div>
            
        </div>
    )

}

export default Footer;