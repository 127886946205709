import {useState, useEffect} from "react";
import './App.css';

import RoutePath from "./routes/RoutePath";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthProvider } from "./contexts/provider/AuthProvider";
import {SearchProvider} from "./contexts/search/search.context.tsx"
import { SiteSettingProvider } from "./contexts/site-settings/site-settings";
import {OutgoingModalProvider} from "./contexts/outgoingmodal/outgoingmodal";
import {SpeclizationFilterProvider} from "./contexts/speclization-filter/speclization-filter.context.tsx";
import {CallingAudioProvider} from "./contexts/callingaudio/callingaudio";

import ScrollToTop from "react-scroll-to-top";
import { BiUpArrow } from "react-icons/bi";
import ReactGA from "react-ga4";

function App() {
	const [theme, setTheme] = useState('light')
	useEffect(() => {
		ReactGA.initialize('G-5D8TKR1LMV');
		const rand_num = localStorage.getItem('rand_num')
		//console.log('rand num=====', rand_num)
		const theme = localStorage.getItem('theme')
		if(theme){
			setTheme(theme)
		}
		
		if(!rand_num){
			const min = 1000000000;
			const max = 9999999999;
			const rand = Math.floor(Math.random() * (max - min + 1)) + min;
			localStorage.setItem('rand_num',rand);
		}
	})


	return (
		<div dir="ltr" class={theme}>
			<div className="flex flex-col min-h-screen transition-colors duration-150 bg-gray-100">
			<SiteSettingProvider>
			<SpeclizationFilterProvider>
			<OutgoingModalProvider>
			<CallingAudioProvider>
			<AuthProvider>
				<SearchProvider>
					<RoutePath />
				</SearchProvider>
			</AuthProvider>
			</CallingAudioProvider>
			</OutgoingModalProvider>
			</SpeclizationFilterProvider>
			</SiteSettingProvider>
			</div>
			<ToastContainer theme="colored" />    
			<ScrollToTop smooth component={<BiUpArrow color='white' />} className="flex items-center justify-center" style={{backgroundColor:'#009f7f',bottom: '60px',
right: '15px'}} />
		</div>
	);
}

export default App;
